import { Counter } from '@/components/Counter'

import { formatDistance, subDays } from 'date-fns'
import { zhTW } from 'date-fns/locale'

export default () => {
  return (
    <>
      <h1>Welcome</h1>
      This page is:
      <ul>
        <li>Rendered to HTML.</li>
        <li>
          Interactive. <Counter />
        </li>
        <li>
          {formatDistance(subDays(new Date(), 3), new Date(), {
            locale: zhTW,
            addSuffix: true,
          })}
        </li>
      </ul>
    </>
  )
}
